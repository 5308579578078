<template>
  <!-- 打印发票 -->
  <ColorTextBtn :loading="loading" @click="print(data)">打印发票</ColorTextBtn>
</template>

<script>
import printJS from 'print-js'
export default {
  props: {
    data: {
      type: Object,
      require: true
    }
  },

  data() {
    return {
      loading: false,

      printObj: {
        printable: require('@/assets/images/default.png'),
        type: 'pdf',
        scanStyles: false,
        documentTitle: '发票',
        onLoadingStart: () => {
          this.loadingInstance = this.$loading({
            lock: true,
            text: '生成发票中'
          })
        },
        onLoadingEnd: () => {
          this.loadingInstance.close()
        },
        onError: (err) => {
          this.loadingInstance.close()
        },
        onPrintDialogClose: () => {
          //发射关闭打印事件
          this.$emit('printHandlerEnd')
        }
      }
    }
  },

  methods: {
    async print(row) {
      const { expressInvoiceFilePath } = row
      if (!expressInvoiceFilePath) {
        this.$message.error('发票地址为空，无法打印！')
        return
      }

      this.loading = true
      this.printObj.printable = expressInvoiceFilePath

      //发射打印开始事件
      this.$emit('printHandlerStart')
      setTimeout(() => {
        this.loading = false
        printJS(this.printObj)
      }, 50)
    }
  }
}
</script>

<style>
</style>